<template>
  <main>
    <SectionHero :variant="hero.variant">
      <OrganismHero :hero="hero" />
    </SectionHero>
    <SectionDefault>
      <Builder :blocks="blocks" :context="context" />
    </SectionDefault>
  </main>
</template>

<script>
import Builder from '../../components/builder/Builder'
import SectionDefault from '../../components/templates/layouts/SectionDefault'
import pageBuilder from '../../components/mixins/pageBuilder.js'
import SectionHero from '../../components/templates/layouts/SectionHero'
import OrganismHero from '../../components/organisms/layouts/OrganismHero'

const mixinPageBuilder = pageBuilder({
  pageUrl: 'fitness',
})

export default {
  name: 'Fitness',
  components: {
    Builder,
    SectionDefault,
    OrganismHero,
    SectionHero,
  },
  mixins: [mixinPageBuilder],
  props: ['context'],
  meta: {
    theme: 'dark',
  },
  data: c => {
    return {
      hero: {
        variant: 'transparent-parallax',
        title1: 'Фитнес',
        hideDesc: '-md:hidden',
        desc: 'Мы&nbsp;разделили занятия на&nbsp;три блока по&nbsp;степени нагрузки и&nbsp;направленности, отсекли малоэффективные, устаревшие методики и&nbsp;дополнили настоящими фитнес-хитами и&nbsp;новинками',
        image: 'fitness/group-training/hero.webp',
        imageWrap: 'img-border-0',
        imageMobile: 'fitness/group-training/hero-mobile.webp',
      },
    }
  },
}
</script>
